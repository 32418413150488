@font-face {
  font-family: 'Rubik-Light';
  src: local('Rubik-Light'), url(../fonts/Rubik-Light.woff) format('woff');
}
@font-face {
  font-family: 'Rubik-Regular';
  src: local('Rubik-Regular'), url(../fonts/Rubik-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Rubik-Bold';
  src: local('Rubik-Bold'), url(../fonts/Rubik-Bold.woff) format('woff');
}