.content-boxes__section {
  margin-top: 20px;
}

.content-boxes,
.content-boxes--half {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.content-box__clickable {
  cursor: pointer;
}

.content-boxes__section-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--ac-grey);
}


@media screen and (min-width: 600px) {
  .content-boxes {
    grid-template-columns: 1fr 1fr;
  }
  .content-boxes--half {
    grid-template-columns: 1fr 1fr;
  }
  .content-boxes--three-in-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .content-boxes {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .content-boxes--half {
    grid-template-columns: 1fr 1fr;
  }
  .content-boxes--three-in-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.content-box {
  padding: 10px;
  border-radius: 3px;
  background-color: var(--ac-white);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  position: relative;
}

.content-box__title {
  text-align: center;
  font-size: 18px;
  display: block;
  padding: 10px 0 10px;
  font-family: 'Rubik-Bold', sans-serif;
}

.content-box__subtitle {
  text-align: center;
  font-size: 38px;
  display: block;
  padding: 10px 0 0;
  font-family: 'Rubik-Bold', sans-serif;
}

.content-box__input {
  width: 100px;
  font-size: 38px;
  font-family: 'Rubik-Bold', sans-serif;
  color: var(--ac-dark-grey);
  padding: 0;
  margin: 0;
  margin-top: -15px;
  border: 3px solid var(--ac-grey);
  border-radius: 3px;
  border-style: dashed;
}

.content-box__tinytitle {
  font-size: 12px;
}

.content-box__link {
  font-size: 12px;
  text-align: center;
  display: block;
  text-decoration: underline;
  color: var(--ac-blue);
  padding-bottom: 10px;
  cursor: pointer;
}

.content-box__icon {
  text-align: center;
}

.content-box--gradient-green {
  @include gradient-green();
  color: var(--ac-white);
}

.content-box--gradient-blue {
  @include gradient-blue();
  color: var(--ac-white);
}

.content-box--gradient-purple {
  @include gradient-purple();
  color: var(--ac-white);
}

.content-box--gradient-red {
  @include gradient-red();
  color: var(--ac-white);
}

.content-box--gradient-orange {
  @include gradient-orange();
  color: var(--ac-white);
}

.content-box--gradient-white {
  @include gradient-white();
}

.cb__main {
  font-size: 52px;
  line-height: 52px;
  font-family: 'Rubik-Bold', sans-serif;
  text-shadow: 1px 1px rgba(0,0,0,0.05);
}

.cb__units {
  font-size: 14px;
}

.cb__subtitle {
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  margin-top: -10px;
}

.cb__subtitle--larger {
  font-size: 15px;
  font-family: 'Rubik-Regular', sans-serif;
  margin-bottom: 30px;
}

.cb__grid {
  display: grid;
  grid-template-columns: 70px auto;
  grid-gap: 10px;
}

.cb__icon {
  .ac-icon {
    border: 2px solid rgba(255,255,255,0.2);
    display: block;
    width: 70px;
    height: 70px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    background: rgba(255,255,255,0.4);
  }
}

.cb__section {
  margin-bottom: 20px;
}

.cb__section--last {
  margin-bottom: 0;
}

.cb__section h3 {
  margin-bottom: 10px;
}

.content-box__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.75);
  border-radius: 3px;
  z-index: 1;
  color: var(--ac-white);
  font-family: 'Rubik-Bold', sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

