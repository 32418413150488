.legend {
  font-size: 12px;
  font-family: 'Rubik-Regular', sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.legend__label {
  display: inline-block;
  padding: 0 20px;
}

.label__id {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}