/* @VEHCICLES MAP */
.vehicles-map {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 800px) {
  .vehicles-map {
    grid-template-columns: 1fr 200px;
  }
}