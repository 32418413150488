.eco-score__section-title {
  font-size: 20px;
  font-family: 'Rubik-Bold', sans-serif;
  text-transform: uppercase;
}

.eco-score__content {
  margin-top: 10px;
}

.eco-score__category {
  margin-bottom: 20px;
}

.eco-score__category:last-child {
  margin-bottom: 0;
}

.eco-score__content--gas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.eco-score__card {
  border: 2px dashed var(--ac-dark-grey);
  padding: 10px 15px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  position: relative;
}

.eco-score__card--title {
  font-size: 14px;
  font-family: 'Rubik-Bold', sans-serif;
  text-transform: uppercase;
}

.eco-score__card--icon {
  position: absolute;
  top: 0;
  right: 0;
}

.eco-score__card--subtitle {
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 30px;
  text-align: center;
  border: 2px solid var(--ac-dark-grey);
  background: var(--ac-white);
  border-radius: 30px;
  padding: 5px 22px;
  height: 40px;
  line-height: 26px;
  position: absolute;
  top: 130px;
  left: 10px;
  background: var(--ac-alert-success-darker);
  color: var(--ac-white);
  text-shadow: 1px 1px rgba(0, 0, 0, 25%);
  min-width: 90px;
}

.eco-score__card--subtitle--gold {
  background: #dea24e;
}

.eco-score__card--subtitle--silver {
  background: #8f8d8d;
}

.eco-score__card--subtitle--bronze {
  background: #99511a;
}



@media screen and (min-width: 800px) {
  .eco-score__content--gas {
    grid-template-columns: 200px 200px 200px;
  }
}