.preloader-overlay {
  width: 100%;
}

.preloader-overlay.fullscreen {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.90);
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
}

.preloader-overlay.fullscreen .preloader-center {
  height: 100vh;
  display: grid;
}

.preloader-overlay.fullscreen .preloader {
  display: block;
  width: 80px;
  height: 80px;
  align-self: center;
  justify-self: center;
}

.preloader-overlay.preloader-center {
  height: auto;
  text-align: center;
}