.invoice ul {
  list-style: none;
}

.invoice table {
  width: 100%;
}

.invoice thead {
  background-color: var(--ac-blue);
}

.invoice th {
  padding: 5px 10px;
  color: var(--ac-white);
  text-align: left;
}

.invoice td {
  padding: 5px 10px;
}


.invoice h3 {
  font-size: 13px;
  color: var(--ac-grey);
  margin-bottom: 10px;
}

.invoice__from {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
}

.invoice__to {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
}

.invoice__body {
  margin-bottom: 100px;
}

.invoice__logo {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 50px auto;
}

.invoice__toolbar {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.invoice__button {
  text-align: right;
}