.fuel-prices {
  margin-top: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.fuel-price {
  border: 3px dashed var(--ac-light-grey);
  padding: 15px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  position: relative;
}

.fuel-price--petrol {
  background: rgba(49,151,121,0.1);
}

.fuel-price--diesel {
  background: rgba(41,47,49,0.1);
}

.fuel-price--lpg {
  background: rgba(60,123,145,0.1);
}

.fuel-price__title {
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'Rubik-Bold', sans-serif;
  line-height: 24px;
  margin-bottom: 10px;
}

.fuel-price__icon {
  position: relative;
  left: -10px;
}

.fuel-price__value {
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 30px;
  text-align: center;
  border: 2px solid var(--ac-dark-grey);
  background: var(--ac-white);
  border-radius: 30px;
  padding: 5px 22px;
  height: 40px;
  line-height: 26px;
  position: absolute;
  top: 110px;
  left: 10px;
  background: var(--ac-alert-info-darker);
  color: var(--ac-white);
  text-shadow: 1px 1px rgba(0,0,0,0.25);
  min-width: 90px;
}

.fuel-price__value--editing {
  border: 2px dashed var(--ac-dark-grey);
}

.fuel-price__value--editing input {
  color: var(--ac-dark-grey);
  text-shadow: none;
}

.fuel-price__value span {
  font-size: 16px;
}


.statistics-card__value--petrol {
  background: var(--ac-alert-success-darker);
}

.statistics-card__value--diesel {
  background: var(--ac-alert-neutral);
}

.statistics-card__value--lpg {
  background: var(--ac-alert-info-darker);
}

.statistics-card__value--petrol.fuel-price__value--editing,
.statistics-card__value--diesel.fuel-price__value--editing,
.statistics-card__value--lpg.fuel-price__value--editing,
.statistics-card__value--cng.fuel-price__value--editing {
  background: var(--ac-white);
}


.fuel-price__input {
  width: 90px;
  padding: 0;
  border: none;
  background: none;
  color: var(--ac-white);
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 30px;
  position: absolute;
  text-align: center;
  right: 0;
  top: -1px;
  font-family: 'Rubik-Regular', sant-serif;
  text-shadow: 1px 1px rgba(0,0,0,0.25);
}




@media screen and (min-width: 1200px) {
  .fuel-prices {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}


@media screen and (min-width: 1400px) {
  .fuel-prices {
    grid-template-columns: 250px 250px 250px 250px;
  }
}