/* @FOOTER PAGE */
.template__page .footer {
  font-size: 12px;
  padding: 15px 30px;
  text-align: center;
  color: var(--ac-white);
  background: var(--ac-darkest-grey);
}


.template__page .footer__copyright {
  margin-bottom: 10px;
}


.template__page .footer a {
  text-decoration: none;
  color: var(--ac-white);
}


@media screen and (min-width: 600px) {

  .template__page .footer {
    background: var(--ac-lightest-grey);
    color: var(--ac-grey);
  }


  .template__page .footer a {
    color: var(--ac-grey);
  }
  
}




@media screen and (min-width: 992px) {

  .template__page .footer {
    padding: 15px 30px;
  }


  .template__page .footer:after {
    content: "";
    clear: both;
    display: table;
  }


  .template__page .footer__copyright {
    width: 30%;
    float: left;
    text-align: left;
    margin-bottom: 0;
  }


  .template__page .footer__address {
    width: 70%;
    float: left;
    text-align:right;
    margin-bottom: 0;
  }

}




/* @FOOTER AUTH */
.template__auth .footer {
  color: var(--ac-grey);
  font-size: 12px;
  font-family: 'Rubik-Light', sans-serif;
  padding: 30px;
}


.template__auth .footer__copyright {
  margin-bottom: 20px;
  text-align: center;
}


.template__auth .footer__address {
  text-align: center;
  margin-bottom: 20px;
}


.template__auth .footer a {
  display: inline-block;
}


.template__auth .footer a, 
.template__auth .footer a:hover {
  color: var(--ac-grey);
  text-decoration: none;
}


.template__auth .footer__icon{
  font-size: 12px;
  margin-right: 5px;
}


.template__auth .footer--phone{
  margin-left: 20px;
}


@media only screen and (min-width: 600px) {

  .template__auth .footer {
    padding: 12px 0;
  }


  .template__auth .footer__copyright {
    width: 45%;
    float: left;
    text-align: left;
    margin-bottom: 20px;
  }

  
  .template__auth .footer__address {
    width: 55%;
    float: left;
    text-align:right;
    margin-bottom: 20px;
  }

}