/* @TAG */

.tags {
  padding: 30px 30px 20px;
  border-top: 1px solid var(--ac-darkest-grey-transparent);
  background: var(--ac-darkest-grey-transparent);
}

.tag {
  display: inline-block;
  height: 46px;
  line-height: 40px;
  background: var(--ac-white);
  border: 1px solid var(--ac-darkest-grey-transparent);
  padding: 2px 20px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 25px;
  font-weight: normal;
  cursor: pointer;
  transition: all 0.25s ease;
  position: relative;
}

.tag:hover {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
}

.tag--small {
  height: 36px;
  line-height: 30px;
  background: var(--ac-white);
  padding: 2px 40px 2px 20px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 20px;
  border-color: var(--ac-darkest-grey-transparent);
  cursor: default;
}

.tag--small:hover {
  box-shadow: none;
}


.tag__remove {
  position: absolute;
  right: 2px;
  top: 2px;
  background: var(--ac-darkest-grey-transparent);
  border: 1px solid var(--ac-darkest-grey-transparent);
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  line-height: 42px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.tag__remove:hover {
  background: var(--ac-darkest-grey-transparent);
}

.tag-title::after {
    content: ", ";
}

.tag-title:last-child::after {
  content: "";
}