.vehicle-now {
  display: grid;
  grid-template-columns: 1fr;
}

.vehicle-now__state {
  background: var(--ac-white);
  display: grid;
  grid-template-columns: 1fr;
}

.vehicle-now__state-item {
  border-bottom: 1px solid var(--ac-light-grey);
  padding: 8px;
}

.vehicle-now__state-item:last-child {
  border: none;
}

.vehicle-now__map {
  position: realtive;
  overflow: hidden;
}

.vehicle-overview__title {
  border-bottom: 1px solid var(--ac-light-grey);
  padding: 8px 16px;
  font-weight: bold;
}

.vehicle-overview__overview-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  border-bottom: 1px solid var(--ac-light-grey);
  padding: 16px;
}

.vehicle-overview__overview-section:last-child {
  border: none;
}

.vehicle-overview__overview-section.overview-section--small {
  padding: 8px 16px;
}

.vehicle-overview__overview-item {
  display: grid;
  grid-template-columns: 32px auto;
  gap: 10px;
  line-height: 32px;
}

.vehicle-overview__overview-icon {
  background: var(--ac-dark-grey);
  width: 32px;
  height: 32px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  line-height: 44px;
}

.vehicle-overview__overview-item.vehicle-overview__overview-item--nodata .vehicle-overview__overview-description-value,
.vehicle-overview__overview-item.vehicle-overview__overview-item--nodata .vehicle-overview__overview-description-title {
  color: var(--ac-light-grey);
}

.vehicle-overview__overview-item.vehicle-overview__overview-item--nodata .vehicle-overview__overview-icon {
  background: var(--ac-light-grey);
}

.vehicle-overview__overview-icon.vehicle-overview__overview-icon--info {
  background: var(--ac-alert-info-darker);
}

.vehicle-overview__overview-icon.vehicle-overview__overview-icon--success {
  background: var(--ac-alert-success-darker);
}

.vehicle-overview__overview-icon .ac-icon {
  top: 15px;
}

.vehicle-overview__overview-icon.vehicle-overview__overview-icon--info {
  background: var(--ac-alert-info-darker);
}

.vehicle-overview__overview-icon.vehicle-overview__overview-icon--success {
  background: var(--ac-alert-success-darker);
}

.vehicle-overview__overview-icon.vehicle-overview__overview-icon--warning {
  background: var(--ac-alert-warning-darker);
}

.vehicle-overview__overview-icon.vehicle-overview__overview-icon--danger {
  background: var(--ac-alert-danger-darker);
}

.vehicle-overview__overview-description-title {
  text-transform: uppercase;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 10px;
  line-height: 10px;
  color: var(--ac-grey);
  margin-bottom: 7px;
}

.vehicle-overview__overview-description-value {
  font-size: 18px;
  line-height: 14px;
  font-family: 'Rubik-Bold', sans-serif;
}

.overview-section__time {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Rubik-Regular', sans-serif;
}

.overview-section__time em {
  font-family: 'Rubik-Bold', sans-serif;
}

.overview-section__time--error {
  color: var(--ac-alert-danger);
}

.overview-section__time--success {
  color: var(--ac-alert-success);
}



@media screen and (min-width: 1200px) {
  .vehicle-now {
    grid-template-columns: auto 300px;
    grid-auto-flow: dense;
  }

  .vehicle-now__state {
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 16px;
    border: 1px solid var(--ac-light-grey);
    margin: 16px;
    box-shadow: 0px 2px 7px 0px rgba(126, 45, 45, 0.15);
  }

  .vehicle-now__state-item {
    border-right: 1px solid var(--ac-light-grey);
    padding: 8px;
  }

  .vehicle-now__state-item:last-child {
    border: none;
  }

  .vehicle-now__state-item:nth-child(3) {
    border-right: none;
  }

  .vehicle-now__state-item:nth-child(4),
  .vehicle-now__state-item:nth-child(5) {
    border-bottom: none;
  }

  .vehicle-now .vehicle-now__map {
    height: auto;
  }

  .vehicle-now__map {
    height: 500px;
    position: realtive;
    overflow: hidden;
    border-radius: 20px;
    margin: 16px;
    border: 1px solid var(--ac-light-grey);
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  }

  .vehicle-now__vehicle-overview {
    margin: 16px 16px 16px 0;
    border-radius: 16px;
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
    border: 1px solid var(--ac-light-grey);
    background: var(--ac-white);
  }

  .vehicle-overview__tiny-value {
    font-size: 13px;
    text-transform: uppercase;
  }
  
}


@media screen and (min-width: 1600px) {
  .vehicle-now__state {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .vehicle-now__state-item {
    border-bottom: none;
  }
  .vehicle-now__state-item:nth-child(3) {
    border-right: 1px solid var(--ac-light-grey);
  }
}