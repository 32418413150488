.graph-legend__item {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.graph-legend__item-color {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: black;
  margin-right: 4px;
  position: relative;
  top: 2px;
  border-radius: 2px;
}

.graph-legend__item-color.graph-legend__item-color--current {
  background: #2CBFFC;
}

.graph-legend__item-color.graph-legend__item-color--possible {
  background: #E72E5F;
}

.graph-legend__item-value {
  font-family: 'Rubik-Bold', sans-serif;
}

.graph-legend__title {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Rubik-Bold', sans-serif;
  margin-bottom: 8px;
}