.vehicle-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.vehicle-card {
  border: 2px dashed var(--ac-dark-grey);
  padding: 10px 15px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  position: relative;
}


.vehicle-card__value {
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 30px;
  text-align: center;
  border: 2px solid var(--ac-dark-grey);
  background: var(--ac-white);
  border-radius: 30px;
  padding: 5px 10px;
  height: 40px;
  line-height: 26px;
  position: absolute;
  top: 85px;
  left: 10px;
  background: var(--ac-alert-danger-darker);
  color: var(--ac-white);
  text-shadow: 1px 1px rgba(0,0,0,0.25);
}

.vehicle-card__value span {
  font-size: 12px;
  text-transform: uppercase;
}

.vehicle-card__title {
  text-transform: uppercase;
  font-family: 'Rubik-Bold', sans-serif;
  margin-top: -5px;
  position: relative;
}

.vehicle-card__imei {
  font-size: 12px;
  line-height: 12px;
  color: var(--ac-grey);
  font-family: 'Rubik-Bold', sans-serif;
}


@media screen and (min-width: 800px) {
  .vehicle-cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media screen and (min-width: 1000px) {
  .vehicle-cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1400px) {
  .vehicle-cards {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}