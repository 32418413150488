.article {
  section {
    margin-bottom: 50px;
  }
  section:last-child {
    margin-bottom: 0;
  }
  .title-section {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 20px;
  }
  
  h3 {
    margin-bottom: 10px;
  }
  
  ul {
    margin-bottom: 20px;
  }
  


  figure {
    margin: 20px 0;

    img {
      margin-bottom: 5px !important;
    }

    figcaption {
      font-size: 14px;
      font-style: italic;
    }
  }
}


.article .article-footer {
  border-top: 1px solid var(--ac-light-grey);
  padding-top: 30px;
  margin-bottom: 0;
}