/* @MODAL */

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  z-index: 11;
}

.modal__wrapper {
  padding: 0 30px;
  margin-top: -150px;
}

.modal {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  background: var(--ac-white);
  border-radius: 5px;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  position: relative;
}

.modal__btn--close {
  position: absolute;
  top: -15px;
  right: -30px;
}

.btn--margin-right {
  margin-right: 20px;
}

.modal__title {
  border-bottom: 1px solid var(--ac-card-borders);
  padding: 5px 20px;
  color: var(--ac-grey);
}

.modal__body {
  padding: 10px 20px 25px;
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
}

.modal__footer {
  border-top: 1px solid var(--ac-card-borders);
  padding: 5px 20px;
  color: var(--ac-grey);
}


.modal__footer:after {
  content: "";
  display: table;
  clear: both;
}

.modal__sticky-button {
  background: var(--ac-white);
  position: fixed;
  width: 50%;
  bottom: 0;
  padding: 5px;
}

.modal__reset-selection {
  background: none;
  border: none;
  color: var(--ac-blue);
  text-decoration: underline;
}

.modal-body__section {
  margin-bottom: 20px;
}

.modal-body__section h3 {
  margin-bottom: 10px;
}


.payment-option__title {
  margin-bottom: 10px;
}