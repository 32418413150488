/* @DROPDOWN MENU ON BUTTON */
.dropdown{
  position:relative;
}

.card__header .dropdown{
  position:absolute;
  top: 0;
  right: 0;
}

.dropdown ul {
  visibility: hidden;
  opacity: 0;
  position: relative;
  top: 45px;
  right: 8px;
  z-index: 1;
  transition: all 0.5s ease;
  display: none;
  background: var(--ac-white);
  border: 1px solid var(--ac-light-grey);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown.dropdown--toolbar ul{
  top: 5px;
  right: 0;
}

@keyframes slideDownFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.dropdown .dropdown--visible > ul,
.dropdown .dropdown--visible {
  visibility: visible;
  opacity: 1;
  display: block;
  z-index: 11;
  animation-name: slideDownFadeIn;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.dropdown .dropdown__item {
  border-bottom: 1px solid var(--ac-lightest-grey);
}

.dropdown .dropdown__item a {
  text-decoration: none;
  font-size: 14px;
  color: var(--ac-dark-grey);
  display: inline-block;
  width: 100%;
  border-left: 2px solid #fff;
  position: relative;
  transition: all 0.25s ease;
}

.dropdown__item a:hover {
  border-color: var(--ac-blue);
}

.dropdown__item .ac-icon,
.dropdown__icon{
  position: absolute;
  top: 10px;
  left: 10px;
}

.dropdown__title{
  padding: 10px 15px 10px 50px;
  display: inline-block;
  white-space: nowrap;
}