.ignition-off {
  padding: 25px;
  background-color: rgba(236,191,88,0.15);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  border-radius: 3px;
  overflow: hidden;
  border: 3px solid var(--ac-alert-warning);
  margin-bottom: 20px;
  position: relative;
}



.ignition-off__graphic {
  .ac-icon--no-signal {
    position: absolute;
    top: 20px;
    left: 120px;
  }
}


@media screen and (min-width: 600px) {
  .ignition-off {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 150px auto;
  }
}