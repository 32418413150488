/* @COOKIE CONSENT */
.cookie-consent{
  position: fixed;
  bottom:0;
  width: 100%;
  z-index: 100;
  background-color: var(--ac-app-secondary-color);
  color: var(--ac-white);
  text-align:center;
  padding: 20px 30px;
  line-height: 24px;
}

.cookie-consent a{
  color: var(--ac-white);
  text-decoration: underline;
}