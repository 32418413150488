.icon--success {
  background: var(--ac-alert-success);
  color: var(--ac-white);
  position: relative;
  top: 7px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.icon--danger {
  background: var(--ac-alert-danger);
  color: var(--ac-white);
  position: relative;
  top: 7px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.icon--rotate {
  transition-property: transform;
  transition-duration: 1s;
  animation-name: rotate; 
  animation-duration: 2s; 
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}


.table__menu.table__icon{
  line-height: 24px;
}

.table__icon{
  position: relative;
  margin-right: 5px;
  line-height: 38px;
}

.simple .table__icon{
  top: 0;
}

.table__icon--task {
  float: right;
  color: var(--ac-white);
  background: var(--ac-blue);
  border: 1px solid var(--ac-blue-borders);
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 52px;
  border-radius: 50%;
  transition: all 0.25s ease;
}

.table__icon--task:hover{
  background: var(--ac-darkest-grey);
  border-color: var(--ac-darkest-grey);
}

.table__icon--error {
  background: var(--ac-alert-danger);
  color: var(--ac-white);
  display: inline-block;
  line-height: 45px;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
}

.table__icon--success {
  background: var(--ac-alert-success);
  color: var(--ac-white);
  display: inline-block;
  line-height: 45px;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
}

.table__icon--warning {
  background: var(--ac-alert-warning);
  color: var(--ac-white);
  display: inline-block;
  line-height: 45px;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
}


.table__icon--info {
  background: var(--ac-alert-info);
  color: var(--ac-white);
  display: inline-block;
  line-height: 45px;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
}

.table__icon--button {
  margin-left: 5px;
  cursor: pointer;
}