/* @BUTTON */
.btn{
  -webkit-appearance: none;
  font-size: 18px;
  padding: 8px 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  line-height:30px;
  text-align: center;
  text-decoration:none;
  text-transform: uppercase;
  display: inline-block;
  font-weight: normal;
  transition: all 0.25s ease;
  letter-spacing: 1px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid rgba(0,0,0,0);
}

.btn:hover{
  background: var(--ac-dark-grey);
  color: var(--ac-white);
}

.btn:visited{
  color: var(--ac-white);
}

.btn--primary{
  background: var(--ac-blue);
  color: var(--ac-white);
  border-color: var(--ac-blue-borders);
}

.btn--primary:hover{
  border-color: var(--ac-darkest-grey);
  background: var(--ac-darkest-grey);
}

.btn--centered{
  margin: 0 auto;
  display: block;
  text-align: center;
  width: max-content;
}

.btn--menu{
  width: 36px;
  height: 36px;
  padding: 0;
  text-align: center;
  line-height: 36px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.btn--menu .material-icons{
  line-height: 36px;
}

.btn--card-header{
  display: block;
  width: 100%;
  margin: 10px 0;
}

.btn--text{
  font-size: 14px;
  padding: 5px 18px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  line-height:20px;
  width: auto;
}

.btn--left{
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right:10px;
}

.btn--selection{
  margin: 30px 0; 
}

.btn--table{
  font-size: 12px;
  padding: 3px 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  line-height:20px;
  width: auto;
}

.btn--disabled,
input[type=submit]:disabled,
button:disabled,
button[disabled] {
  color: var(--ac-grey);
  border-color: var(--ac-light-grey);
  background: var(--ac-light-grey);
  cursor: default;
}

.btn--disabled:hover,
input[type=submit]:disabled:hover,
button:disabled:hover,
button[disabled]:hover {
  color: var(--ac-grey);
  border-color: var(--ac-light-grey);
  background: var(--ac-light-grey);
  cursor: default;
}

.btn--circular{
  -webkit-appearance: none;
  font-size: 18px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  line-height:40px;
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration:none;
  text-transform: uppercase;
  display: inline-block;
  font-weight: normal;
  transition: all 0.25s ease;
  letter-spacing: 1px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: none;
  background: var(--ac-blue);
  border: 1px solid var(--ac-blue-borders);
  color: var(--ac-white);
  float: left;
  margin-right: 15px;
}

.btn--circular:hover{
  background: var(--ac-dark-grey);
  color: var(--ac-white);
  border-color: var(--ac-dark-grey);
}

.btn--active {
  background-color: var(--ac-dashboard-green);
}

.btn--inactive {
  background-color: var(--ac-dashboard-red);
}


@media screen and (min-width: 600px) {
  .btn--right{
      float: right;
  }

  .btn--left{
      float: left;
  }

  .btn--card-header {
    position: relative;
    width: auto;
    margin: 0;
  }
}


@media screen and (min-width: 992px) {

  .btn{
      font-size: 14px;
      padding: 5px 18px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
      line-height:20px;
      width: max-content;
  }

  .btn--large{
      font-size: 18px;
      padding: 8px 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      line-height:30px;
  }

  .btn--fullwidth{
      width: 100%;
      display: inline-block;
  }

  .btn--menu{
      width: 36px;
      height: 36px;
      padding: 0;
      line-height: 36px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
  }

  .btn--inline {
    display: inline-block;
  }

}


@media screen and (min-width: 1170px) {
  .btn--card-header {
    width: auto;
  }
}