/* @SIDEBAR */
.sidebar{
  display: none;
}

.sidebar h3 {
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  margin-top: 40px;
  text-align: center; 
}

.sidebar__notification-counter{
  position: absolute;
  top: 22px;
  right: 10px;
  background: var(--ac-blue);
  color: var(--ac-white);
  font-size: 10px;
  width:20px;
  height:20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.notification-counter--alert {
  background: var(--ac-pink);
}

.notification-counter--success {
  background: var(--ac-turqoise);
}

@media screen and (min-width: 600px) {
.sidebar{
  display: block;
  width: 60px;
  background: var(--ac-dark-grey);
  box-sizing: border-box;
  padding-top: 60px;
  float: left;
  min-height: 100%;
}

.sidebar__nav-title{
  display: none;
}

.sidebar__nav-item{
  display: block;
  position: relative;
  text-decoration: none;
  color: var(--ac-white);
  position: relative;
  transition: all 0.25s ease;
  padding: 16px;
  margin-bottom: 0;
  line-height: 40px;
}

.sidebar__nav-item--current, .sidebar__nav-item:hover{
  background: var(--ac-darkest-grey);
  color: var(--ac-blue);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px inset;
}

.sidebar__nav-item .ac-icon,
.sidebar__nav-item .material-icons {
  position: relative;
  top: 8px;
}

.sidebar__notification-counter{
  top: 9px;
}

}



@media screen and (min-width: 992px) {
  
  .sidebar {
    float: left;
    min-height: 100%;
    width: 250px;
  }

  .sidebar h3 {
    padding-left: 15px;
    text-align: left;
  }

  .sidebar__main-nav{
    min-height: 100%;
    padding: 30px 20px 60px;
  }

  .sidebar__nav-item{
    display: block;
    position: relative;
    text-decoration: none;
    color: var(--ac-white);
    padding: 12px;
    position: relative;
    margin-bottom: 10px;
    transition: all 0.25s ease;
  }

  .sidebar__nav-item--current, .sidebar__nav-item:hover{
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    color: var(--ac-blue);
  }

  .sidebar__nav-item .ac-icon,
  .sidebar__nav-item .material-icons {
    position: absolute;
    top: 6px;
  }

  .sidebar__nav-title{
    display: block;
    padding-left: 40px;
    line-height: 14px;
    position: relative;
    top: 1px;
  }

}