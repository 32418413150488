.cta-box {
  background-color: var(--ac-white);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  border-radius: 3px;
  overflow: hidden;
  border: 3px solid var(--ac-light-grey);
}

.cta-box__padding-wrapper {
  padding: 30px;
}

.cta-box.cta-box--info {
  border: 3px solid var(--ac-alert-info);
}

.cta-box.cta-box--warning {
  border: 3px solid var(--ac-alert-warning);
}

.cta-box__header {
  background: var(--ac-light-grey);
  padding: 5px;
}

.cta-box.cta-box--info .cta-box__header {
  background: var(--ac-alert-info);
}

.cta-box.cta-box--warning .cta-box__header {
  background: var(--ac-alert-warning);
}


.cta-box__icon {
  text-align: center;
  margin: 0 auto;
}

.cta-box__icon .ac-icon {
  position: relative;
  top: 7px;
}

.cta-box__content {
  text-align: center;
  padding: 25px;
}

.cta-box__content-title {
  font-size: 28px;
  font-family: 'Rubik-Bold', sans-serif;
  margin-bottom: 15px;
}

.template__page .main-content .cta-box__content-text:last-child {
  margin-bottom: 0;
}

.cta-box__icon-info {
  background-color: var(--ac-white);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  border-radius: 20px;
  height: 70px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 200px;
}

.cta-box__icon-info .ac-icon {
  position: relative;
  top: 10px;
  margin-left: -20px;
}

.cta-box__icon-info span {
  font-size: 36px;
  padding-left: 10px;
  position: relative;
  top: -7px;
}