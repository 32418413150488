.statistics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.statistics-card {
  border: 2px dashed var(--ac-dark-grey);
  padding: 10px 15px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  position: relative;
}

.statistics-card__icon {
  margin-bottom: 20px;
}

.statistics-card__help {
  position: absolute;
  top: 0;
  right: 0;
}

.statistics-card__title {
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Rubik-Bold', sans-serif;
  line-height: 14px;
}

.statistics-card__value {
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 30px;
  text-align: center;
  border: 2px solid var(--ac-dark-grey);
  background: var(--ac-white);
  border-radius: 30px;
  padding: 5px 10px;
  height: 40px;
  line-height: 26px;
  position: absolute;
  top: 110px;
  left: 10px;
  background: var(--ac-alert-info-darker);
  color: var(--ac-white);
  text-shadow: 1px 1px rgba(0,0,0,0.25);
}

.statiscics-card__noigl {
  position: absolute;
  left: 10px;
  top: 10px;
}

.statistics-card__value--good {
  background: var(--ac-alert-success-darker);
}

.statistics-card__value--neutral {
  background: var(--ac-alert-warning-darker);
}

.statistics-card__value--bad {
  background: var(--ac-alert-danger-darker);
}

.statistics-card__value span {
  font-size: 16px;
}

.fuel-price__link {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
}

.fuel-prices-info {
  margin: 50px 0;
}

.fuel-prices-info__title {
  text-transform: uppercase;
  font-family: 'Rubik-Bold', sans-serif;
  margin-bottom: 8px;
}

.fuel-prices-info__items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid var(--ac-white);
  border-radius: 16px;
  max-width: 600px;
  overflow: hidden;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
}

.fuel-prices-info__item {
  padding: 8px 16px;
  border-right: 1px solid var(--ac-white);
  background-color: var(--ac-light-grey);
  color: var(--ac-white);
}

.fuel-prices-info__item:last-child {
  border: none;
}

.fuel-prices-info__item--petrol {
  background-color: #319779;
}
.fuel-prices-info__item--diesel {
  background-color: #292f31;
}
.fuel-prices-info__item--lpg {
  background-color: #3c7b91;
}
.fuel-prices-info__item--cng {
  background-color: #3c7b91;
}

.fuel-prices-info__item-title {
  font-size: 12px;
  font-family: 'Rubik-Bold', sans-serif;
  text-transform: uppercase;
}

.fuel-prices-info__item-value {
  font-size: 32px;
  font-family: 'Rubik-Bold', sans-serif;
}

.fuel-prices-info__item-value span {
  font-size: 12px;
}

@media screen and (min-width: 500px) {
  .statistics {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 700px) {
  .statistics {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media screen and (min-width: 800px) {
  .statistics {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}


@media screen and (min-width: 1200px) {
  .statistics {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1400px) {
  .statistics {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1600px) {
  .statistics {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1800px) {
  .statistics {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 2000px) {
  .statistics {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}