.payment__options {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.payment-option {
  border: 1px solid transparent;
  background: linear-gradient(
    0deg
    ,#fff,#f0f5f5);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.payment-option:hover {
  border: 1px solid var(--ac-blue);
}

.payment-option--selected {
  border: 1px solid var(--ac-blue);
}


.payment__term {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

.term-option {
  border: 1px solid transparent;
  background: linear-gradient(
    0deg
    ,#fff,#f0f5f5);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.term-option:hover {
  border: 1px solid var(--ac-blue);
}

.term-option--selected {
  border: 1px solid var(--ac-blue);
}



.selected__vehicles {
  padding: 5px 10px;
  border: 1px solid var(--ac-blue);
  background: linear-gradient(
    0deg
    ,#fff,#f0f5f5);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.25s ease;
}


.payment-option__savings {
  font-size: 12px;
  font-family: 'Rubik-Bold', sans-serif;
}


.payment__total {
  font-size: 24px;
  font-family: 'Rubik-Regular', sans-serif;
  margin-top: 10px;
}