.workers-state {
  border: 1px solid var(--ac-light-grey);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
}

.worker-state__item {
  background: white;
  border-bottom: 1px solid var(--ac-light-grey);
  display: grid;
  grid-template-columns: auto 70px 130px;
}

.worker-state__item .worker-state__item-cell {
  padding: 8px 16px;
}


.worker-state__item:last-child {
  border: none;
}


.worker-state__label {
  border: 1px solid transparent;
  padding: 0px 8px;
  display: inline-block;
  border-radius: 2px;
  font-size: 12px;
}

.worker-state__label--success {
  background: var(--ac-alert-success);
  color: var(--ac-white);
}

.worker-state__label--danger {
  background: var(--ac-alert-danger);
  color: var(--ac-white);
}