/* @REACT DATEPICKER - MAIN STYLES */
.react-datepicker__header.react-datepicker-year-header {
  text-align: center;
  font-weight: bold;
  position: relative;
  color: var(--ac-dark-grey);
  margin-bottom: 10px;
}


.react-datepicker__aria-live {
  display: none !important;
}


.form .react-datepicker-wrapper {
  width: 100%;
  max-width: 400px;
}


.react-datepicker-wrapper {
  width: 120px;
}

.react-datepicker-popper{
  background: var(--ac-white);
  border: 1px solid var(--ac-light-grey);
  font-size: 13px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  z-index: 1;
}


.react-datepicker__header--time{
  padding: 10px 0 0 0;
  border-bottom: 1px solid #F5F5F5;
}

.react-datepicker__time-box{
  border-right: 1px solid #D8D8D8;
}

.react-datepicker__current-month, 
.react-datepicker-time__header{
  font-family: 'Rubik-Regular', sans-serif;
  padding: 0 8px;
  margin-bottom: 5px;
  text-align: left;
}

.react-datepicker__navigation:before{
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 22px;
  margin: 5px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-family: 'Rubik-Regular', sans-serif;
  border: none;
  background: var(--ac-grey);
  color: var(--ac-white);
} 

.react-datepicker__navigation--next{
  visibility: hidden;
  position: absolute;
  top: 0px;
  right: 12px;
  z-index: 2;
  width: 30px;
  height: 40px;
}

.react-datepicker__navigation--next:before  {
  visibility: visible;
  font-family: 'Material Icons'; 
  font-size: 16px; 
  display: inline-block; 
  content: '\e409';
  position: relative;
  top: 0px;
  right: 10px;
  cursor: pointer;
}

.react-datepicker__navigation--previous{
  visibility: hidden;
  position: absolute;
  top: 0px;
  right: 37px;
  z-index: 2;
  width: 30px;
  height: 40px;
}

.react-datepicker__navigation--previous:before  {
  visibility: visible;
  font-family: 'Material Icons'; 
  font-size: 16px; 
  display: inline-block; 
  content: '\e408';
  position: relative;
  top: 0px;
  right: 10px;
  cursor: pointer;
}


/* @TIME PICKER*/
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid var(--ac-light-grey);
  width: 300px;
  background: var(--ac-white);
}

.react-datepicker--time-only .react-datepicker__header--time {
  padding: 3px;
  font-family: 'Rubik-Regular', sans-serif;
}

.react-datepicker--time-only .react-datepicker__header {
  text-align: center;
  background-color: var(--ac-dark-grey);
  padding-top: 3px;
  position: relative;
  color: var(--ac-white);
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: var(--ac-white);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 300px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  background: var(--ac-white);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}


.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 50px;
  padding: 15px;
  font-size: 18px;
}




.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected
{
  background-color: var(--ac-blue);
  color: white;
  font-family: 'Rubik-Regular', sans-serif;
}





@media screen and (min-width: 992px) {
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 100px;
  }

  .react-datepicker__time-container {
      width: 100px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      height: 30px;
      padding: 5px 10px;
      font-size: 14px;
  }
}


.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker__navigation--previous:before {
  content: '‹'
}

.react-datepicker__navigation--next:before {
  content: '›'
}



/* DAY CALENDAR */
.react-datepicker__current-month {
  color: var(--ac-dark-grey);
}

.react-datepicker__day-names {
  background: var(--ac-lightest-grey);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 8px;
}

.react-datepicker__day-name {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin: 5px;
  font-family: 'Rubik-Regular', sans-serif;
}

.react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 8px;
  margin-top: 8px;
}

.react-datepicker__day {
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  color: var(--ac-dark-grey);
  font-family: 'Rubik-Regular', sans-serif;
  cursor: pointer;
}

.react-datepicker__day--selected
{
  background-color: var(--ac-blue);
  color: var(--ac-white);
}

.react-datepicker__day--today{
  font-family: 'Rubik-Bold', sans-serif;
}

.react-datepicker__day.react-datepicker__day--disabled {
  color: var(--ac-light-grey);
}



/* MONTH CALENDAR */
.react-datepicker__month-container {
  padding: 8px;
  max-width: 320px;
}

.react-datepicker__month-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin-bottom: 8px;
}

.react-datepicker__month-text {
  padding: 0 8px;
  border: 1px solid var(--ac-dark-grey);
  color: var(--ac-dark-grey);
  border-radius: 4px;
  font-family: 'Rubik-Regular', sans-serif;
  cursor: pointer;
  text-align: center;
  width: 90px;
}

.react-datepicker__month--selected {
  background-color: var(--ac-blue);
  color: var(--ac-white);
  font-family: 'Rubik-Regular', sans-serif;
}

.react-datepicker__month--disabled  {
  color: lightgrey;
  border-color: lightgrey;
  cursor: not-allowed;
  background: #F5F5F5;
}




/* YEAR CALENDAR */
.react-datepicker__year--container {
  padding: 8px;
  width: 300px;
}

.react-datepicker__year-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}

.react-datepicker__year-text {
  padding: 0 8px;
  border: 1px solid var(--ac-dark-grey);
  color: var(--ac-dark-grey);
  border-radius: 4px;
  font-family: 'Rubik-Regular', sans-serif;
  cursor: pointer;
  display: inline-block;
}

.react-datepicker__year-text--selected {
  background-color: var(--ac-blue);
  color: var(--ac-white);
  font-family: 'Rubik-Regular', sans-serif;
}

.react-datepicker__year-text--disabled {
  color: lightgrey;
  border-color: lightgrey;
  cursor: not-allowed;
  background: #F5F5F5;
}