/* @ANIMATION SLIDE IN FROM BOTTOM */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animation--slide-in-from-bottom {
  animation: 0.4s ease-in-out 0s 1 slideInFromBottom;
}


/* @ANIMATION FADE IN */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation--fade-in {
  animation: 0.4s ease-in-out 0s 1 fadeIn;
}


/* @ANIMATION FADE OUT */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animation--fade-out {
  animation: 0.4s ease-in-out 0s 1 fadeOut;
}