.no-igl {
  padding: 25px;
  background-color: rgba(236,191,88,0.15);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  border-radius: 3px;
  overflow: hidden;
  border: 3px solid var(--ac-alert-warning);
  margin-bottom: 20px;
  position: relative;
}

.no-igl__graphic {
  .ac-icon--no-igl-warning {
    position: absolute;
    top: 25px;
    left: 25px;
  }
}

.no-igl__content-title {
  font-size: 32px;
  font-family: 'Rubik-Bold', sans-serif;
  margin-bottom: 15px;
}


@media screen and (min-width: 600px) {
  .no-igl {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 150px auto;
  }
}