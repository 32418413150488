:root {

  /* @APP COLOR */
  --ac-app-primary-color: #33385A;
  --ac-app-secondary-color: #ED2324;

  /* @COLORS*/
  --ac-red: #BA0808;
  --ac-pink: #E34B5F;
  --ac-lighter-pink: rgb(224, 144, 155);
  --ac-lightest-pink: rgb(230, 195, 200);
  --ac-hover-pink: rgb(247, 229, 231);
  --ac-blue: #2CBFFC;
  --ac-blue-borders: #27aadd;
  --ac-white: #FFFFFF;
  --bg-highlight: #FAFFBD;
  --ac-lightest-grey: #F5F5F5;
  --ac-light-grey: #D4D4D4;
  --ac-grey: #9697AE;
  --ac-darkest-grey: #2A2C44;
  --ac-dark-grey: #33385A;
  --ac-dark-blue: #1D2836;
  --ac-lightest-blue: #E7FBFF;
  --ac-turqoise: #33C2BD;
  --ac-lightest-yellow: #FFFFE0;
  --ac-card-borders: #e9e9e9;

  /* @COLORS DASHBOARD */
  --ac-dashboard-black: #000000;
  --ac-dashboard-darkest-grey: #1E2733;
  --ac-dashboard-dark-grey: #303D50;
  --ac-dashboard-white: #F5F5F5;
  --ac-dashboard-red: #E62D49;
  --ac-dashboard-green: #01A840;
  --ac-dashboard-orange: #FACB4E;

  /* @COLORS TRANSPARENT */
  --ac-darkest-grey-transparent: rgba(42, 44, 68, 0.85);
  --ac-white-transparent: rgba(255, 255, 255, 0.2);

  /* @ALERTS */
  --ac-alert-danger: #D84A49;
  --ac-alert-warning: #ECBF58;
  --ac-alert-success: #5BC3A3;
  --ac-alert-info: #70B1C7;

  --ac-alert-danger-darker: #b82a2a;
  --ac-alert-warning-darker: #b68d2e;
  --ac-alert-success-darker: #319779;
  --ac-alert-info-darker: #3c7b91;
  --ac-alert-neutral: #292f31;


  --ac-dialogflow-violet: #FF00AD;
  
}