.module {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 50px;
}

.module:last-child {
  margin-bottom: 0;
}

.module__title {
  text-align: center;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.module__icon {
  width: 140px;
  height: 140px;
  border-radius: 70px;
  margin: 0 auto -20px;
  background: var(--ac-white);
  padding-top: 25px;
  text-align: center;
}

.modules--unprocessed .module__icon {
  border: 3px solid var(--ac-light-grey);
}

.modules--processing .module__icon {
  border: 3px dashed var(--ac-light-grey);
}

.modules--processed .module__icon {
  border: 3px solid var(--ac-dark-grey);
}

.module__labels {
  max-width: 400px;
  margin: 0 auto;
}

.module__labels-group {
  margin-bottom: 20px;
}

.module__labels-group:last-child {
  margin-bottom: 0;
}

.module__label {
  border-radius: 25px;
  padding: 5px 15px;
  font-family: 'Rubik-Bold', sans-serif;
  background: var(--ac-white);
  margin-bottom: 5px;
  position: relative;
  padding-left: 35px;
}

.module__label span {
  text-transform: uppercase;
}

.modules--unprocessed .module__label {
  border: 2px solid var(--ac-light-grey);
  color: var(--ac-light-grey);
}

.modules--processing .module__label {
  border: 2px dashed var(--ac-light-grey);
  color: var(--ac-light-grey);
}

.modules--processed .module__label {
  border: 2px solid var(--ac-dark-grey);
  color: var(--ac-dark-grey);
}

.module__label:last-child {
  margin-bottom: 0;
}

.module__label.label--unknown {
  border: 2px solid var(--ac-light-grey);
  color: var(--ac-light-grey);
}

.module__label .icons {
  position: absolute;
  top: 5px;
  left: 5px;
}

@media screen and (min-width: 800px) {
  .modules {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  .module {
    margin-bottom: 0;
  }
}