/* @SAVINGS BOX */
.savings__box{
  padding-bottom: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.fuel-prices__box:last-child{
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}


.savings__box:after{
  content: '';
  display: block;
  clear: both;  
}

.savings-type{
  background: var(--ac-darkest-grey);
  color: var(--ac-white);
  display: block;
  float: left;
  width: 100%;
  height: 140px;
  text-align: center;
  padding: 15px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  box-sizing: border-box;
}

.savings__box:last-child{
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.savings-type__title{
  display: block;
  margin-bottom: 17px;
}

.savings-type__total{
  font-size: 34px;
  font-weight: 100;
  margin-bottom: 17px;
}

@media screen and (min-width: 992px) {
  .savings__box{
      width: 48%;
      margin-right: 2%;
      float: left;
      padding: 10px;
      border-bottom: none;
      margin-bottom: 0;
      padding-right: 3%;
  }
}

@media screen and (min-width: 1170px) {
  .savings__box{
      width: 23%;
      margin-right: 2%;
      float: left;
      padding: 10px;
      border-right: 1px solid var(--ac-lightest-grey);
      border-bottom: none;
      margin-bottom: 0;
      padding-right: 3%;
  }
}


.icon--purple{
  @include gradient-purple();
}

.icon--green{
  @include gradient-green();
}

.icon--blue{
  @include gradient-blue();
}

.icon--red{
  @include gradient-red();
}

.icon--orange{
  @include gradient-orange();
}