.tooltip-wrapper .tooltip{
  opacity: 0;
  transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.tooltip-wrapper:hover .tooltip{
  opacity: .85;
}

.tooltip {
  position: absolute;
  z-index: 10;
  left: 0px;
  top: 0px;
  margin-top: -15px;
}

.tooltip-label {
  position: absolute;
  width: auto;
  padding: 2px 6px;
  color: #fff;
  text-align: center;
  background-color: var(--ac-dark-blue);
  border-radius: 2px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 10px;
  line-height: 1em;
}