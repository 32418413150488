/* @TEMPLATE PAGE */
/* @MAIN CONTENT */

.template__page {

  .main-content {
    width: 100%;
    min-height: calc(100vh - 60px);
    position: relative;
    top: 60px;
  }

  .main-content.blur {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
    overflow: hidden;
  }

  .main-content__wrapper {
    background: var(--ac-white);
    min-height: calc(100vh - 168px);
    padding: 0;
  }

  .main-content__wrapper:after {
    content: '';
    display: block;
    clear: both;
  }

  .main-content p {
    margin-bottom: 20px;
  }

  .main-content img {
    height: auto;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 600px) {
    
    .main-content {
      width: calc(100% - 60px);
      float: left;
      min-height: 100%;
    }

    .main-content__wrapper {
      background: rgb(245,245,245);
      background: linear-gradient(135deg, rgba(245,245,245,1) 0%, rgba(233,233,233,1) 100%);
      min-height: calc(100vh - 114px);
      padding: 30px;
    }

  }

  @media screen and (min-width: 992px) {

    .main-content {
      width: calc(100% - 250px);
      min-height: 100%;
      float: left;
    }

  }

}