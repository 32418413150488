.dialogflow {
  display: block;
  background: var(--ac-white);
  position: fixed;
  bottom: 80px;
  right: 10px;
  max-width: 20%;
  min-width: 400px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  z-index: 100;
}


.dialogflow__titlebar {
  background: var(--ac-app-primary-color);
  color: var(--ac-white);
  padding: 8px;
  height: 40px;
}


.dialogflow__bot-name {
  font-family: 'Rubik-Bold';
  font-size: 14px;
  position: relative;
  top: -7px;
}


.dialogflow__bot-image {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: url('../img/hana.jpg');
  background-size: cover;
  background-position: center;
  margin-right: 8px;
}


.dialogflow__time {
  padding: 8px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border-bottom: 1px solid var(--ac-light-grey);
  background: var(--ac-lightest-grey);
}


.dialogflow__chat {
  padding: 8px;
  max-height: 500px;
  overflow-y: scroll;
}


.dialogflow__policy {
  padding: 8px;
  font-size: 12px;
  line-height: 14px;
  border-top: 1px solid var(--ac-light-grey);
  background: var(--ac-lightest-grey);
}


.dialogflow__bubble-wrapper {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
}


.dialogflow__bubble-wrapper:last-child {
  margin-bottom: 0;
}


.dialogflow__bubble-wrapper.dialogflow__bubble-wrapper--user {
  justify-content: flex-end;
}


.dialogflow__bubble-wrapper.dialogflow__bubble-wrapper--user .dialogflow__bubble {
  background-color: var(--ac-blue);
  color: var(--ac-white);
}


.dialogflow__bubble-wrapper.dialogflow__bubble-wrapper--bot .dialogflow__bubble {
  background-color: var(--ac-light-grey);
}


.dialogflow__avatar {
  margin-right: 8px;
  font-size: 12px;
}


.dialogflow__bubble {
  background: var(--ac-lightest-grey);
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  min-width: 300px;
}


.dialogflow__avatar {
  text-align: center;
}


.dialogflow__avatar-image {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: url('../img/hana.jpg');
  background-size: cover;
  background-position: center;
}


.dialogflow__avatar-name {
  line-height: 1px;
}


.dialogflow__input {
  border-top: 1px solid var(--ac-light-grey);
  padding: 8px;
  position: relative;
}


.dialogflow__input input {
  width: 100%;
  border: 1px solid var(--ac-light-grey);
  padding: 8px;
  border-radius: 4px;
}


.dialogflow__submit {
  position: absolute;
  top: 12px;
  right: 6px;
  cursor: pointer;
  background: none;
  border: none;
}


.dialogflow-icon {
  display: block;
  background: var(--ac-blue);
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  z-index: 100;
  cursor: pointer;
  text-align: center;
  line-height: 90px;
  transition: all 0.5s ease;
}


.dialogflow-icon:hover {
  bottom: 14px;
}



@media screen and (min-width: 600px) {

}