.attention-box {
  padding: 20px;
  border: 3px solid var(--ac-lighter-pink);
  border-radius: 3px;
  margin-top: 20px;
}

.attention-box__info {
  font-weight: bold;
  margin-bottom: 20px;
}