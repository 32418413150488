/* @CARD INFO */
.card-info {
  position: relative;
  background: var(--ac-white);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.5);
}

.card-info__header {
  text-align: center;
  padding: 10px;
  background: var(--ac-app-primary-color);
  background-image: url(../img/bg-auth-panel-red.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.card-info__body {
  padding: 30px;
}

.card-info__body-nav {
  display: block;
  height: 40px;
}

@media screen and (min-width: 600px) {
  .card-info {
    border-radius: 3px;
    overflow: hidden;
  }
}