/* @SERVER PAGES 404 & 503 */
.server-page{
  width: 100%;
  height: 100vh;
  color: var(--ac-white);
}

.server-page.server-page--error404{
  background-image: url(../img/404.jpg);
  background-size: cover;
  background-position: center;
}

.server-page.server-page--error503{
  background-image: url(../img/503.jpg);
  background-size: cover;
  background-position: center;
}

.server-page__content{
  display: table;
  width: 100%;
  height: 100%;
}

.server-page__wrapper{
  display: table-cell;
  vertical-align: middle;
}

.server-page__card{
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 60px 30px;
}

.server-page__title{
  font-size: 84px;
  margin-bottom: 50px;
}

.server-page__subtitle{
  font-size: 32px;
  margin-bottom: 50px;
  line-height: 48px;
}