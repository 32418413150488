.overview__card--tile {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
}

.overview__tile-grid {
  display: grid;
  grid-template-columns: 42px auto;
  column-gap: 10px;
}

.overview__tile-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  line-height: 75px;
  font-weight: bold;
}

.overview__tile-overlay.overlay--active {
  background: rgba(0,0,0,0.75);
}

.overview__main-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.overview__icon {
  width: 44px;
  height: 44px;
  text-align: center;
  color: var(--ac-white);
  line-height: 62px;
  display: inline-block;
  background: rgba(255,255,255,0.25);
  border: 1px solid rgba(255,255,255,0.3);
  position: relative;
  top: 6px;
  border-radius: 50%;
}

.overview__title {
  font-size: 14px;
  color: var(--ac-white);
}

.overview__value {
  font-size: 24px;
  color: var(--ac-white);
}


@media screen and (min-width: 600px) {
  .overview__grid {
    margin-left: -10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }
}