.form-group__switch {
  margin-bottom: 20px;
}

.form-group__switch--large {
  margin-bottom: 30px;
}

.form-group__switch .label-title {
  position: relative;
  top: -6px;
  cursor: pointer
}


.switch-wrapper {
  display: inline-block;
  margin-right: 20px;
  .switch {
    width: 100px;
    height: 50px;
    background-color: var(--ac-light-grey);
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: 5px;
    cursor: pointer;
  }
  
  .switch[data-ison="true"] {
    justify-content: flex-end;
    background-color: var(--ac-blue);
  }
  
  .handle {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
  }
}






@media screen and (min-width: 700px) {
  .switch-wrapper {
    .switch {
      width: 52px;
      height: 24px;
      padding: 2px;
    }
  
    .handle {
      width: 20px;
      height: 20px;
      background-color: white;
      border-radius: 10px;
    }
  
    .form-group__switch {
      margin-bottom: 10px;
    }
  
    .form-group__switch .label-title {
      top: -4px;
    }
  
    .form-group__switch--large {
      margin-bottom: 20px;
    }
  }


}



.form-group__switch--fixed .label-title {
  position: relative;
  top: -4px;
  cursor: pointer
}

.switch-wrapper--fixed {
  display: inline-block;
  margin-right: 10px;

  .switch {
    width: 52px;
    height: 24px;
    background-color: var(--ac-light-grey);
    display: flex;
    justify-content: flex-start;
    border-radius: 20px;
    padding: 2px;
    cursor: pointer;
  }
  
  .switch[data-ison="true"] {
    justify-content: flex-end;
    background-color: var(--ac-blue);
  }
  
  .handle {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 10px;
  }

  .form-group__switch .label-title {
    position: relative;
    top: 4px;
  }
}




