/* @SIDENAV */
.sidenav {
  display:none;
  min-height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  background-color: var(--ac-dark-grey);
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav--visible{
  display: block;
  width: 250px;
  z-index: 4;
}

.sidenav::-webkit-scrollbar { 
  display: none; 
}

.sidenav__main-nav{
  padding: 60px 0;
}


.sidenav__nav-item{
  display: block;
  position: relative;
  text-decoration: none;
  color: var(--ac-white);
  padding: 20px;
  position: relative;
  transition: all 0.25s ease;
}

.sidenav__nav-item--current, .sidebar__nav-item--current:visited, .sidenav__nav-item:hover{
  background: var(--ac-darkest-grey);
  color: var(--ac-blue);
}

.sidenav__nav-item .ac-icon,
.sidenav__nav-item .material-icons {
  position: absolute;
  top: 20px;
}

.sidenav__nav-title{
  padding-left: 40px;
  line-height: 14px;
  position: relative;
  top: 1px;
}

.sidenav__nav-title--notification-counter{
  position: absolute;
  top: 18px;
  right: 10px;
  background: var(--ac-pink);
  color: var(--ac-white);
  font-size: 10px;
  width:20px;
  height:20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}


.sidenav__nav-title--vehicle-counter{
  position: absolute;
  top: 18px;
  right: 10px;
  background: var(--ac-alert-success);
  color: var(--ac-white);
  font-size: 10px;
  width:20px;
  height:20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}