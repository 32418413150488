.range {
  margin-top: 10px;
}

.range__rail {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  background: var(--ac-light-grey);
  box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.3);
  margin-bottom: 2px;
}

.range__rail-value {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  border-radius: 5px;
  width: 0%;
  background: var(--ac-dark-grey);
  box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.3);
  transition: width 1.5s ease-in-out;
  display: block;
}

.range__label {
  font-size: 13px;
  font-weight: normal;
  color: var(--ac-dark-grey);
}

.rail-gradient-red{
  @include rail-gradient-red();
}

.rail-gradient-orange{
  @include rail-gradient-orange();
}

.rail-gradient-purple{
  @include rail-gradient-purple();
}

.rail-gradient-blue{
  @include rail-gradient-blue();
}

.rail-gradient-green{
  @include rail-gradient-green();
}