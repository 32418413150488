/* @MSG */
.msg {
  color: var(--ac-white);
  background-color: var(--ac-alert-info);
  padding: 10px 10px 10px 40px;
  line-height: 24px;
  font-family: 'Rubik-Regular', sans-serif;
  margin-bottom: 15px;
  position: relative;

  a {
    color: var(--ac-white);
  }

  .icons, .ac-icon {
    position: absolute;
    left: 10px;
    top: 9px;
  }
}

.message {
  display: inline-block;
}

.ac-alert a {
  color: var(--ac-white);
}

.msg--danger {
  background: var(--ac-alert-danger);
}

.msg--success {
  background: var(--ac-alert-success);
}

.msg--warning {
  background: var(--ac-alert-warning);
}

.msg--info {
  background: var(--ac-alert-info);
}

.msg.msg--tabnav {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}


.msg.msg--no-margin {
  margin: 0
}

.msg.msg--margin-top {
  margin-top: 20px
}


@media screen and (min-width: 600px) {
  .msg {
    margin-bottom: 20px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
}



/* @DATA */
.data {
  background-color: var(--ac-white);
  padding: 10px 10px 10px 10px;
  line-height: 24px;
  font-family: 'Rubik-Regular', sans-serif;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid var(--ac-lightest-grey);
  border-left: 3px solid var(--ac-alert-info);
}

.data--info {
  border-left: 3px solid var(--ac-alert-info);
}

.msg--danger {
  border-left: 3px solid var(--ac-alert-danger);
}

.msg--success {
  border-left: 3px solid var(--ac-alert-success);
}

.msg--warning {
  border-left: 3px solid var(--ac-alert-warning);
}