.module-info {
  margin-bottom: 30px;
}

.module-info__label {
  border-radius: 15px;
  border: 2px solid var(--ac-dark-grey);
  color: var(--ac-dark-grey);
  padding: 10px 15px;
  font-family: 'Rubik-Bold', sans-serif;
  background: var(--ac-white);
  max-width: 220px;
  margin: 0 auto;
}

.module-info__icon {
  width: 140px;
  height: 140px;
  border-radius: 70px;
  border: 3px solid var(--ac-dark-grey);
  margin: 0 auto -20px;
  background: var(--ac-white);
  padding-top: 25px;
  text-align: center;
}




.pairing-button {
  border: 2px solid var(--ac-dark-grey);
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  background: var(--ac-white);
  margin-bottom: 20px;
  transition: all 0.25s ease;
}

.form .pairing-button select,
.form .pairing-button input {
  max-width: 100%;
}

.pairing-button.pairing-button--disabled {
  border-color: var(--ac-light-grey);
}

.pairing-button.pairing-button--disabled .pairing-button__icon.pairing-button__icon--success,
.pairing-button.pairing-button--disabled .pairing-button__icon.pairing-button__icon--info,
.pairing-button.pairing-button--disabled .pairing-button__icon.pairing-button__icon--alert {
  background: var(--ac-white);
  border: 2px solid var(--ac-light-grey);
  color: var(--ac-white);
}

.pairing-button.pairing-button--disabled:hover {
  border-color: var(--ac-light-grey);
  box-shadow: none;
}

.pairing-button:last-child {
  margin-bottom: 0;
}

.pairing-button:hover {
  border-color: var(--ac-blue);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25)
}

.pairing-button__icon {
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 40px;
  margin: 0 auto;
  margin-bottom: 20px;
  line-height: 130px;
  border: 2px solid var(--ac-white);
  background: var(--ac-light-grey);
}

.pairing-button__icon.pairing-button__icon--info {
  background: var(--ac-alert-info);
  border-color: var(--ac-dark-grey);
}

.pairing-button__icon.pairing-button__icon--success {
  background: var(--ac-alert-success);
  border-color: var(--ac-dark-grey);
}

.pairing-button__icon.pairing-button__icon--alert {
  background: var(--ac-alert-danger);
  border-color: var(--ac-dark-grey);
}


.pairing-button .form-group:last-child {
  margin-bottom: 0;
}


.pairing {
  text-align: center;
}

.pairing-circle {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto;
  position: relative;
}


.pairing-state-title {
  font-family: 'Rubik-Bold', sans-serif;
  color: var(--ac-light-grey);
  font-size: 24px;
}

.pairing-state-phrase {
  font-size: 32px;
  margin-top: 20px;
  line-height: 38px;
  font-family: 'Rubik-Bold', sans-serif;
  text-transform: uppercase;
}


.pairing-state-icon {
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 30px;
  position: absolute;
  border: 3px solid var(--ac-light-grey);
  background: var(--ac-white);
  z-index: 2;
  line-height: 94px;
  text-align: center;
}


.pairing-state-icon--paired {
  top: 30px;
  right: 30px;
}

.pairing-state-icon--unlocked {
  top: 100px;
  right: 0px;
}



.pairing-state-icon.pairing-state-icon--processed {
  border: 3px solid var(--ac-dark-grey);
}

.pairing-state-icon.pairing-state-icon--processing {
  border: 3px dashed var(--ac-light-grey);
}

.pairing-state-icon.pairing-state-icon--success {
  background: var(--ac-alert-success);
}

.pairing-state-icon.pairing-state-icon--info {
  background: var(--ac-alert-info);
}

.pairing-state-icon.pairing-state-icon--alert {
  background: var(--ac-alert-danger);
}

.pairing-state-icon.pairing-state-icon--error {
  border-color: var(--ac-alert-danger) !important;
  background: var(--ac-alert-danger);
}


.pairing-circle .icon-vehicle-settings {
  position: absolute;
  top: 54px;
  left: 45px;
}

.pairing-state-time {
  position: absolute;
  bottom: 30px;
  left: 30px;
  border: 3px solid var(--ac-light-grey);
  color: var(--ac-light-grey);
  background: var(--ac-white);
  border-radius: 20px;
  padding: 5px 20px;
  font-family: 'Rubik-Bold', sans-serif;
  width: 240px;
  text-align: center;
  text-transform: uppercase;
}

.pairing-state-time--processed {
  border: 3px solid var(--ac-dark-grey);
  color: var(--ac-dark-grey);
}

.pairing-state-time--processing {
  border: 3px dashed var(--ac-light-grey);
  color: var(--ac-light-grey);
}

.pairing-circle__unprocessed {
  background-image: url(../img/pairing-circle-unprocessed.png);
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.75;
}

.pairing-circle__processing {
  background-image: url(../img/pairing-circle-processing.gif);
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}

.pairing-circle__completed {
  background-image: url(../img/pairing-circle-completed.png);
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}

.pairing-history {
  margin-top: 30px;
}

.pairing-history h3 {
  margin-bottom: 20px;
}

.pairing-history table {
  border: 1px solid var(--ac-light-grey);
}


.pairing-information {
  margin: 30px 0;
}

.driving-state-icon, 
.connected-state-icon,
.compatible-state-icon,
.maintenance-state-icon {
  border: 2px solid var(--ac-light-grey);
  background: var(--ac-white);
  padding: 5px 10px;
  border-radius: 20px;
  font-family: 'Rubik-Bold', sans-serif;
  color: var(--ac-light-grey);
  margin-bottom: 10px;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
}

.compatible-state-icon {
  margin-bottom: 40px;
}

.state-icon--processing {
  border: 2px dashed var(--ac-light-grey);
}

.state-icon--processed {
  border: 2px solid var(--ac-dark-grey);
  color: var(--ac-dark-grey);
}



.icon-state{
  padding-left: 25px;
  text-transform: uppercase;
}

.icon-state .icons {
  position: absolute;
  top: 5px;
  left: 5px;
}

.maintenance-time {
  text-transform: capitalize;
}


@media screen and (min-width: 800px) {

  .pairing-circle-wrapper {
    width: 300px;
    height: 300px;
    display: block;
    margin: 0 auto;
    position: relative;
  }

  .pairing-buttons {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  .pairing-button {
    margin-bottom: 0;
    padding: 50px 20px;
  }

  .pairing-button.pairing-button--datepicker {
    padding: 20px;
  }

  .form .pairing-button .react-datepicker-wrapper {
    max-width: 250px;
    margin: 0 auto;
  }

  .driving-state-icon, 
  .connected-state-icon,
  .compatible-state-icon {
    position: absolute;
    left: -120px;
    margin-bottom: 0;
  }

  .driving-state-icon {
    top: 35px;
  }
  
  .connected-state-icon {
    top: 85px;
  }
  
  .compatible-state-icon {
    top: 135px;
  }

  .maintenance-state-icon {
    position: absolute;
    right: -70px;
    top: 85px;
    margin-bottom: 0;
    z-index: 0;
  }

  .module-info {
    position: absolute;
    bottom: 0;
  }

  .module-info--gas {
    left: -170px;
  }

  .module-info--telemetry {
    right: -200px;
  }

}



@media screen and (min-width: 1200px) {

  .pairing-buttons {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  .pairing-buttons.pairing-buttons--three {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
  }

}