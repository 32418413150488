/* @TEMPLATE PAGE FULLWIDTH */
.template__page-fullwidth {
  
  .main-content {
    width: 100%;
    min-height: 100vh;
    position: relative;
  }

  .main-content__wrapper {
    min-height: 100vh;
  }

  .centered-content {
    margin: 0 auto;
    max-width: 760px;
    padding: 30px;
  }

}


@media screen and (min-width: 600px) {

  .template__page-fullwidth {

    .main-content {
      background: url(../img/bg-auth.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  
  }

}