/* @MAP */

/* MAP WRAPPERS */
.wrapper {
  position: absolute;
  z-index: 1;
}

.wrapper--top-left {
  top: 60px;
  left: 10px;
}


/* MAP CONTAINER FOR SPECIFIC VIEWS */

/* MAP WRAPPER */
.google-maps-wrapper {
  min-height: 300px;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 0;
}


@media screen and (min-width: 600px) {
  .google-maps-wrapper {
    min-height: 500px;
    height: calc(100vh - 350px);
  }
}

@media screen and (min-width: 960px) {
  .google-maps-wrapper {
    min-height: 500px;
    height: calc(100vh - 280px);
  }
}


/* MAP CONTAINER */
.map-constraints-container {
  min-height: 300px;
  height: 100vh;
}

.map-constraints-container--small {
  min-height: 300px;
  height: 100vh;
}

@media screen and (min-width: 600px) {
  .map-constraints-container{
    min-height: 500px;
    height: calc(100vh - 350px);
  }

  .map-constraints-container--small {
    min-height: 300px;
    height: calc(100vh - 350px);
  }
}


@media screen and (min-width: 960px) {
  .map-constraints-container{
    min-height: 500px;
    height: calc(100vh - 280px);
  }

  .map-constraints-container--small {
    min-height: 300px;
    height: calc(100vh - 280px);
  }
}




/* MAP SPECIFIC WRAPPERS */
.map-refuel-counter {
  background: white;
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  padding: 8px 16px;
  font-size: 18px;
  font-family: 'Rubik-Bold', sans-serif;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
}

@media screen and (min-width: 600px) {
  .map-refuel-counter {
    font-size: 24px;
  }
}



.map-constraints-slider{
  margin-top: 20px;
}

.marker-label-wrapper {
  padding-bottom: 9px;
}

.marker-label {
  background-color: var(--ac-dark-blue);
  opacity: 0.85;
  padding: 5px;
  color: var(--ac-white);
  border-radius: 2px;
  font-size: 11px;
  border: 1px solid var(--ac-darkest-grey);
}

.marker-label--nowrap {
  white-space: nowrap;
}

.marker-label-title {
  font-weight: bold;
}

.marker-label-pointer {
  content: '';
  display: block;
  position: absolute;
  bottom: 0px;
  left: 10px;
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 9px solid rgba(42,44,68,0.85)
}


.marker-position {
  width: 15px;
  height: 15px;
  background-color: var(--ac-blue);
  display: block;
  clip-path: polygon(100% 50%, 100% 100%, 50% 50%, 0 100%, 0 50%, 50% 0);
  position: absolute;
  top: 2px;
  left: 2px;
}

.marker-border {
  width: 19px;
  height: 19px;
  background-color: var(--ac-dark-blue);
  display: block;
  clip-path: polygon(100% 50%, 100% 100%, 50% 50%, 0 100%, 0 50%, 50% 0);
}

.marker-time {
  position: absolute;
  top: -30px;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 1px 2px;
  border-radius: 2px;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse:after {
  position: absolute;
  top: 24px;
  left: 4px;
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px var(--ac-blue);
  animation-delay: 1.1s;
}

.pulse.pulse-success:after {
  box-shadow: 0 0 1px 2px var(--ac-alert-success);
}

.pulse.pulse-danger:after {
  box-shadow: 0 0 1px 2px var(--ac-alert-danger);
}

.legend-wrapper {
  background: rgba(29,40,54,0.85);
  color: var(--ac-white);
  padding: 10px 0 10px 10px;
  border-radius: 3px;
  font-size: 14px;
}

.legend-wrapper--routes {
  max-height: 220px;
}

.legend-wrapper--cards {
  bottom: 10px;
  left: 10px;
  max-height: 220px;
}

.legend-routes {
  max-height: 195px;
  overflow-y: scroll;
  padding-right: 15px;
}

.legend-route {
  cursor: pointer;
}

.legend-route-active {
  color: var(--ac-blue);
}



.route-item-color {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: var(--ac-blue);
  position: relative;
  bottom: -2px;
  border: 1px solid var(--ac-white);
}

.marker-station {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  top: -48px;
  left: -24px;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
  }
}

.marker-competitor {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  top: -48px;
  left: -24px;
  position: absolute;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
  }
}

.legend-routes-more {
  text-align: center;
  position:relative;
  top: -8px;
  animation: animationFadeSlideUp 2s 1s ease-out infinite;
  opacity: 0;
}

.checkpoint-info-panel {
  background: rgba(29,40,54,0.85);
  color: var(--ac-white);
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: 10px;
  border-radius: 3px;
  font-size: 14px;
  max-height: 220px;
  font-size: 12px;
  line-height: 18px;
  max-height: 220px;
  overflow: hidden;
  overflow-y: scroll;
}

.checkpoint-info-panel__scrollable-wrapper{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 30px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}


.checkpoint-info-panel__title {
  font-family: 'Rubik-Bold', sans-serif;
}


.legend-route-point-wrapper {
  background: rgba(29,40,54,0.85);
  color: var(--ac-white);
  padding: 5px;
  border-radius: 3px;
  font-size: 20px;
  font-family: 'Rubik-Bold', sans-serif;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
}


@keyframes animationFadeSlideUp {
  0% {
    opacity: 0;
    transform: translateY(3px);
  }
  50% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translateY(3px);
  }
}





@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}


/* @VEHICLE LIST */
.vehicles-map {
  max-height: calc(100vh - 200px);
}

.vehicles-map__vehicles-list {
  border-left: 1px solid var(--ac-card-borders);
  max-height: calc(100vh - 200px);
  overflow: scroll;
  background: var(--ac-lightest-grey);
  padding: 2px 0;
}

.vehicles-list__vehicle-card-wrapper {
  padding: 2px 4px;
}

.vehicles-list__vehicle-card {
  background: var(--ac-white);
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid var(--ac-card-borders);
}

.vehicles-list__vehicle-card:hover {
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.05);
}

.vehicle-card__vehicle-title {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: -5px;
}

.vehicle-card__vehicle-subtitle {
  font-size: 12px;
  color: var(--ac-light-grey);
}

.vehicle-card__main {
  padding: 5px;
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-column-gap: 10px;
}

.vehicle-card__ignition {
  padding-top: 6px;
}

.vehicle-card__data {
  font-size: 12px;
  text-align: center;
  border-top: 1px solid var(--ac-card-borders);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.vehicle-card__data-item {
  border-right: 1px solid var(--ac-card-borders);
  height: 30px;
  padding-top: 2px;
}

.data-item--success {
  background: var(--ac-alert-success);
  color: var(--ac-white);
}

.data-item--warning {
  background: var(--ac-alert-warning);
  color: var(--ac-white);
}

.data-item--danger {
  background: var(--ac-alert-danger);
  color: var(--ac-white);
}

.vehicle-card__data-item:last-child {
  border: none;
}

.vehicle-card--selected {
  border-color: var(--ac-blue);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  animation: popup 0.25s ease-out;
}


@keyframes popup {
  0% {
    transform: scale(0.4, 0.4);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.vehicles-map__vehicle-overview {
  position: absolute;
  bottom: 23px;
  left: 10px;
  z-index: 9;
  display: block;
  background: var(--ac-white);
  border-radius: 3px;
  animation: popup 0.25s ease-out;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  padding: 3px;
}

.vehicle-overview__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1px;
  font-family: 'Rubik-Regular', sans-serif;
}

.vehicle-overview__grid-item {
  padding: 5px;
  background: var(--ac-lightest-grey);
  width: 75px;
}

.vehicle-overview__title {
  font-size: 8px;
  line-height: 8px !important;
  text-transform: uppercase;
  text-align: center;
}

.vehicle-overview__value {
  text-align: center;
  color: var(--ac-darkest-grey);
}


.vehicles-map__reports-button {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background: var(--ac-lightest-grey);
  border-radius: 3px;
  z-index: 9;
  bottom: 120px;
  left: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.vehicles-map__connection-info {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background: var(--ac-lightest-grey);
  border-radius: 3px;
  z-index: 9;
  bottom: 120px;
  left: 60px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  text-align: center;
  line-height: 54px;
}

.vehicles-map__connection-info.connection-info--disconnected {
  background: var(--ac-alert-danger);
}

.vehicles-map__connection-info.connection-info--connected {
  background: var(--ac-alert-success);
}


.map-statins--on-off {
  position: absolute;
  bottom: 20px;
  left: 10px;
  background: var(--ac-white);
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}

.map-follow-route--on-off {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: var(--ac-white);
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}

.map-follow-route--on-off .form,
.map-follow-route--on-off .form-group__switch,
.map-follow-route--on-off label,
.map-statins--on-off .form,
.map-statins--on-off .form-group__switch,
.map-statins--on-off label {
  padding: 0;
  margin: 0;
}

.map-follow-route--on-off label,
.map-statins--on-off label {
  height: 24px;
  font-size: 14px;
}

.map-follow-route--on-off .switch-wrapper,
.map-statins--on-off .switch-wrapper {
  margin-right: 10px;
}