/* @PAGINATION */
.pagination{
  font-size: 12px;
  color: var(--ac-grey);
  margin-top: 25px;
}

.pagination__items-text,
.pagination__perpage-text, 
.pagination__perpage-select,
.pagination__item-num,
.pagination__helper-text{
  margin-left: 20px;
  position: relative; 
  top: -7px;
}

.pagination__next,
.pagination__prev{
  display: inline-block;
  background: var(--ac-light-grey);
  border: 1px solid var(--ac-light-grey);
  width: 40px;
  height: 40px;
  line-height: 55px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  margin-left: 5px;
}

a.pagination__next,
a.pagination__prev,
.pagination__next--active,
.pagination__prev--active{
  background: var(--ac-blue);
  border: 1px solid var(--ac-blue-borders);
  color: var(--ac-white);
  cursor: pointer;
}

.pagination__next .material-icons,
.pagination__prev .material-icons{
  position: relative;
  top: 7px;
  line-height: 12px;
}

.pagination__perpage-text, 
.pagination__perpage-select,
.pagination__helper-text{
  display:none;
}

.pagination__nav {
  margin-right: 20px;
}

.pagination__item-num {
  display:inline-block;
  margin-left: 60px;
}

.pagination__item-num {
  margin-left: 20px;
}

.pagination__select{
  -webkit-appearance: none;
  font-size: 12px;
  border: none;
  border-bottom: 1px solid var(--ac-light-grey);
  background: var(--ac-white);
  padding: 5px 20px;
  color: var(--ac-dark-grey);
  font-family: 'Rubik-Regular', sans-serif;
  font-weight: 100;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  transition: all 0.25s ease;
  box-sizing: border-box;
}

@media screen and (min-width: 800px) {
  .pagination{
      text-align: left;
  }

  .pagination__perpage-text, 
  .pagination__perpage-select,
  .pagination__helper-text{
      margin-right: 0;
      margin-left: 20px;
  }

  .pagination__perpage-text, 
  .pagination__perpage-select{
      display: inline-block;
  }

  .pagination .pagination__helper-text{
      float: left;
      margin-left: 0;
  }
}