/* @TEMPLATE AUTH */
/* @MAIN CONTENT */

.template__auth {

  .main-content {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  .main-content p {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 600px) {

    .main-content {
      background: url(../img/bg-auth.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .main-content__wrapper{
      margin: 0 auto;
      max-width: 800px;
      padding: 50px 30px;
    }

  }

}