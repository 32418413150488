/* @ALERTS */

.alerts__settings {
  border-bottom: 1px solid var(--ac-lightest-grey);
  margin-bottom: 30px;
}


.alerts {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 30px;
}


@media screen and (min-width: 600px) {
  .alerts {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 900px) {
  .alerts {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .alerts {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1600px) {
  .alerts {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1900px) {
  .alerts {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 2400px) {
  .alerts {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.alert {
  padding: 10px;
  border-radius: 3px;
  background-color: var(--ac-white);
  border: 1px solid var(--ac-white);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  transition: all 0.25s ease;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.alert:hover {
  border-color: var(--ac-blue);
}

@media screen and (min-width: 700px) {
  .alert {
    padding: 20px;
  }
}

.alert__title {
  font-size: 18px;
  font-family: 'Rubik-Regular', sans-serif;
  display: block;
  margin-bottom: 20px;
}

.alert--disabled .alert__title {
  color: var(--ac-light-grey);
}

.alert__settings {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 0.25s ease;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--ac-white);

  .ac-icon {
    position: relative;
    top: 2px;
  }
}


.alert:hover .alert__settings {
  display: block;
  background: var(--ac-blue);
  border-color: var(--ac-blue-borders);
}

.alert .switch {
  position: relative;
  left: 10px;
}