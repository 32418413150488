.maintenance-card {
  padding: 10px;
  border-radius: 3px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 15%);
  transition: all 0.25s ease;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
}

.maintenance-card:last-child {
  margin-bottom: 0;
}

.maintenance-card__title {
  margin-bottom: 10px;
}

.maintenance-card.maintenance-card--disabled .maintenance-card__title {
  color: #D4D4D4;
}