.alert-box {
  background: var(--ac-white);
  margin: -30px;
  padding: 30px;
}

.alert-box__icon {
  /* @include rail-gradient-red(); */
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid var(--ac-white);
}

.alert-box__icon .ac-icon {
  position: relative;
  top: 15px;
}

.alert-box__title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 5px;
}

.alert-box__values {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.alert-box__values.values--single {
  grid-template-columns: 1fr;
}

.alert-box__value {
  text-align: center;
  font-size: 32px;
  line-height: 32px;
  font-family: 'Rubik-Bold', sans-serif;
  padding: 20px 10px;
}

.alert-box__units {
  font-size: 14px;
}

.alert-box__subtitle {
  text-align: center;
  font-size: 15px;
  font-family: 'Rubik-Regular', sans-serif;
  color: var(--ac-grey);
}

.alert-box__date {
  text-align: center;
  margin-bottom: 40px;
  color: var(--ac-grey);
  font-size: 14px;
}

.alert-box__value {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.05); 
  margin-bottom: 30px;
}

.alert-box__subtitle {
  font-size: 12px;
  line-height: 12px;
  color: var(--ac-grey);
  text-shadow: none;
  text-transform: uppercase;
}

.value--default {
  color: var(--ac-grey);
}

.alert-box__footer {
  margin-top: 30px;
}

.alert__long-lat-missing {
  padding: 20px;
  padding-bottom: 0;
}


@media screen and (min-width: 600px) {

  .alert-box {
    background-color: rgba(255,255,255,0.95);
    border: 1px solid var(--ac-white);
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 50px;
    padding: 0;
  }

  .alert-box__values {
    display: grid;
    grid-template-columns: repeat(2fr, 1fr);
    margin: 0;
    border-bottom: 1px solid var(--ac-light-grey);
  }

  .alert-box__values.values--single {
    grid-template-columns: 1fr;
  }

  .alert-box__value {
    margin-bottom: 0;
  }

  .value--true {
    border-right: 1px solid var(--ac-light-grey);
  }

  .alert-box__footer {
    border-top: 1px solid var(--ac-light-grey);
    margin-top: 0;
  }

  .alert-box__footer {
    .btn {
      border: none;
      background: none;
      color: var(--ac-blue);
    }
  }


}